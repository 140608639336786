<template>
  <page ref='wrap'>
    <div ref='content'>
      <header-bar :title='title'>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div v-if='list.length' style="padding: 15px">
        <info-panel
            v-for='(item, index) in list'
            :key='index'
            @delete='_deletePost'
            :index='index'
            :keywordEvent='false'
            :info='item' />
          <div class="endTitle" v-if='list.length && isEnd'>{{endTitle}}</div>
      </div>
    </div>
  </page>
</template>

<script>
import infoPanel from '@/components/infoPanel/index.vue'
import scroll from '@/common/mixins/scroll'
import postApi from '@/api/post'

export default {
  components: {
    infoPanel
  },
  mixins: [scroll],
  data: ()=> ({
    list: [],
    pageNo: 1,
    pageSize: 20,
    isEnd: false,
    endTitle: '',
    title: '查询帖子'
  }),
  methods: {
    getList() {
      if (!this.isEnd) {
        const {styleNoId, companyId, styleNoName, companyCode} = this.$route.query
        const obj = {
          styleNoName: styleNoName,
          styleNoId: styleNoId,
          companyId: companyId,
          companyCode:companyCode,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }

        if (styleNoId) {
          postApi.searchPost2(obj).then(res => {
            if (res.data){
              if (res.data.endFlag) {
                this.isEnd = true
                this.endTitle = res.data.endTitle
              } else {
                this.pageNo++
                this.endTitle = '正在加载....'
              }
              this.list.push(...res.data.postsList)
            }
          })
        } else {
          postApi.searchPostByName(obj).then(res => {
          if (res.data){
            if (res.data.endFlag) {
              this.isEnd = true
              this.endTitle = res.data.endTitle
            } else {
              this.pageNo++
              this.endTitle = '正在加载....'
            }
            this.list.push(...res.data.postsList)
          }
        })
        }
      
      }
    },
    _initList() {
      this.pageNo = 1
      this.list = []
      this.isEnd = false
    },
    _deletePost(e) {
      const {index} = e
      this.list.splice(index, 1)
    },
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (to.name == 'postList') {
        // console.log(to);
        setTimeout(() => {
          vm.title = to.query.title
        }, 250)
      }
    })
  },
  mounted() {
    this.getList()
    const wrap = this.$refs.wrap.$refs.pageContent_contain
    const content = this.$refs.content
    this.onContentScroll(wrap, content, this.isEnd, ()=> {
      this.getList()
    })
  }
}
</script>

<style lang="less" scoped>
.endTitle {
  font-size: 13px;
  text-align: center;
  color: #999;
  margin: 15px 0;
}
</style>
