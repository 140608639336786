export default {
  methods: {
    onContentScroll(wrap, content, isEnd, cb) {
      wrap.addEventListener('scroll', (e)=> {
        this.$global.debounce(()=> {
          console.log(wrap, content);
          const sumH = content.clientHeight
          const scrollH = wrap.clientHeight + wrap.scrollTop
          if (sumH - scrollH <= 180 && !isEnd) {
            cb && cb()
          }

          
          this.$router.history.current.meta.offsetTop = e.target.scrollTop
       
        })
      })
    }
  }
}